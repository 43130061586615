import { render, staticRenderFns } from "./ExhibitionsPage.vue?vue&type=template&id=1202bdae&scoped=true"
import script from "./ExhibitionsPage.vue?vue&type=script&lang=js"
export * from "./ExhibitionsPage.vue?vue&type=script&lang=js"
import style0 from "./ExhibitionsPage.vue?vue&type=style&index=0&id=1202bdae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1202bdae",
  null
  
)

export default component.exports